import React from 'react';
import { useNavigate } from "react-router-dom";
import { useCoinContextData } from "../../context/CoinContext";
import "./Technology.scss";

import logo from "../../static/images/logo.svg";
import hamIcon from "../../static/images/icons/hamIcon.svg";
// import tickPath from "../../static/images/tick.png";
import firstImg from "../../static/images/first1.png";

const Technology = () => {
  const navigate = useNavigate();
  const { preLoginMenu, setPreLoginMenu } = useCoinContextData();

  return (
    <div className='TechnologyPage'>
      <div className="navbar">
        <div className="hamMenu-home">
          <img src={hamIcon} alt="" />
        </div>
        <div
          className="logo"
          onClick={() => {
            navigate("/");
          }}
        >
          <img src={logo} alt="logo" />
        </div>
        <div className="menu-items">
          <div
            onClick={() => {
              navigate("/");
              setPreLoginMenu("About Us");
            }}
          >
            <p style={{ fontWeight: preLoginMenu === "About Us" ? "600" : "" }}>
              About naavi
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/social");
              setPreLoginMenu("SocialDimension");
            }}
          >
            <p style={{ fontWeight: preLoginMenu === "SocialDimension" ? "600" : "" }}>
              Social Dimension
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/Technology");
              setPreLoginMenu("Technology");
            }}
          >
            <p style={{ fontWeight: preLoginMenu === "Technology" ? "600" : "" }}>
              Technology
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/contact");
              setPreLoginMenu("ContactUs");
            }}
          >
            <p style={{ fontWeight: preLoginMenu === "ContactUs" ? "600" : "" }}>
              Contact
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/WhitePaper");
              setPreLoginMenu("WhitePaper");
            }}
          >
            <p style={{ fontWeight: preLoginMenu === "WhitePaper" ? "600" : "" }}>
              White Paper
            </p>
          </div>
        </div>
      </div>
      <div className="color-box"></div>
      <div className="pathEngine container py-5">
        <div className="row mt-5">
          <h1 className="fw-bold mb-3 kg-title">LLMs-Synergised with Knowledge Graphs (KG)</h1>
          <div className="col-md-6">
            <div className="llm-synergised-text">
              <ul>
                <li className='shadow bg-white p-2 rounded mb-2'>Using open-source LLM models such as Llama2, Mistral 7B running locally using Ollama</li>
                <li className='shadow bg-white p-2 rounded mb-2'>With vector/graph databases (Qdrant/Neo4j), Langchain, as well as Knowledge graph embeddings (pykg2vec library)</li>
                <li className='shadow bg-white p-2 rounded'>Synergising LLMs with KGs from the perspective of knowledge representation as well as reasoning on the data related to education paths, and counseling scenarios</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <img className="firstImage" src={firstImg} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Technology;
